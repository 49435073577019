import React from 'react';
import { motion } from 'framer-motion';

function SmallPolygon() {
  return <polygon id='polygon1' fill='#CDDC37' points='7.82,14.19 16.84,8.99 25.85,14.19 25.85,24.6 16.84,29.8 7.82,24.6' />;
}

function MiddlePolygon() {
  return <polygon id='polygon2' fill='#4DAF4E' points='-1.19,8.99 16.84,-1.42 34.86,8.99 34.86,29.8 16.84,40.21 -1.19,29.8' />;
}

function BigPolygon() {
  return <polygon id='polygon3' fill='#1E5F30' points='-10.2,3.78 16.84,-11.83 43.88,3.78 43.88,35.01 16.84,50.62 -10.2,35.01' />;
}

function MetaStudiosGreenM() {
  return (
    <div>
      <svg version='1.1' id='Meta_M_Logo' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 33.52 35.25' enableBackground='new 0 0 336 176.8' xmlSpace='preserve'>
        <g>
          <g id='Meta'>
            <clipPath id='Meta_Clipping_Path_M'>
              <path
                id='Meta_M_Clipping_Path'
                d='M25.99,12.09l-6.68,15.52h-5.04c0,0-6.09-13.69-6.73-15.38H7.43v23.02H0V0h7.95
                c2.2,4.41,8.79,17.72,8.84,17.86L25.59,0h7.92v35.25h-7.43V12.09H25.99z'
              />
            </clipPath>
            <g id='Hexagons' clipPath='url(#Meta_Clipping_Path_M)' style={{ clipPath: 'url(#Meta_Clipping_Path_M)', '-webkit-clip-path': 'url(#Meta_Clipping_Path_M)' }}>
              <BigPolygon />
              <motion.g
                animate={{ rotate: 360 }}
                transition={{
                  duration: 72, //72000 was value of the other one
                  ease: 'linear',
                  repeat: Infinity,
                }}
              >
                <MiddlePolygon />
              </motion.g>
              <motion.g
                animate={{ rotate: 360 }}
                transition={{
                  duration: 36, //36000 was value of the other one
                  ease: 'linear',
                  repeat: Infinity,
                }}
              >
                <SmallPolygon />
              </motion.g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default MetaStudiosGreenM;
