/*****************************************************************************/
/*  File Name: index.js                                                      */
/*  Date:      June 8, 2022                                                  */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   basic footer                                                  */
/*                                                                           */
/*****************************************************************************/

import React from 'react';
import { withTranslation } from 'react-i18next';

//import firebase from 'firebase/app';
import Container from '@mui/material/Container';

import useStyles from './styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MetaStudiosGreenM from '../../images/meta_studios_green_m_animate';

function Footer({ t }) {
  //Load all of the default component varaibles
  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      <Container maxWidth='xl'>
        <Grid container alignItems='center'>
          <Grid item xs={11} align='left'>
            <Typography variant='body2' className={classes.finePrint}>
              <a href='https://metastudios.co' target='_blank' rel='noreferrer' className={classes.urlText}>
                metastudios.co
              </a>
              &nbsp; &nbsp;
              <span className={classes.greenColor}>/</span>
              &nbsp; &nbsp;
              <a href='mailto:leads@metastudios.co' target='_blank' rel='noreferrer' className={classes.urlText}>
                leads@metastudios.co
              </a>
              &nbsp; &nbsp;
              <span className={classes.greenColor}>/</span>&nbsp; &nbsp; P.O. Box 6207 New Hamburg, ON N3A 2K6 &nbsp; &nbsp;
            </Typography>
          </Grid>
          <Grid item xs={1} align='center'>
            <div className={classes.footerLogo}>
              <MetaStudiosGreenM key='footer-icon' />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withTranslation()(Footer);
