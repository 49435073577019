/**************************************************************************/
/*  File Name: index.js                                                   */
/*  Date:      May 29 2022                                                */
/*  Author:    Christopher Cressman                                       */
/*  Purpose:   These are the base styles for the footer section           */
/*             of the site                                                */
/*                                                                        */
/**************************************************************************/
import baseStyles from '../../Styles';
import { makeStyles } from '@mui/styles';
import * as COLORS from '../../../constants/colors';
//import { grey } from '@mui/material/colors';

const styles = (theme) => {
  return {
    ...baseStyles,

    footerContainer: {
      display: 'block',
      position: 'relative',
      background: COLORS.white,
      paddingTop: 30,
      paddingBottom: 30,
    },

    footerLogo: {
      maxWidth: 50,
      width: '80%',
    },

    finePrint: {
      color: COLORS.darkMode,
    },
  };
};

const useStyles = makeStyles((theme) => {
  const pageStyles = styles(theme);

  return { ...pageStyles };
});

export default useStyles;
