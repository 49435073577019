import React, { createContext, useMemo, useState, useContext, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import * as COLORS from '../constants/colors';

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
  mode: 'light',
});

export const ColorModeContextProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setMode] = useState('light');

  useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light');

    return () => {
      setMode('dark');
    };
  }, [prefersDarkMode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      mode,
    }),
    [mode]
  );

  let theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: COLORS.cuteraGreen,
          },
          secondary: {
            main: purple[500],
          },
        },
        typography: {
          fontFamily: 'sofia-pro, sans serif',
          fontWeight: 300,
          h2: {
            fontWeight: 200,
          },
          h4: {
            fontWeight: 300,
          },
        },
      }),
    [mode]
  );

  theme = responsiveFontSizes(theme);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export const useColorMode = () => useContext(ColorModeContext);
