import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import * as ROUTES from '../../constants/routes.js';
import ErrorBoundary from '../Errors';
import Alerts from '../Alerts';
//import SystemAlerts from '../Alerts';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from '../Footer';

const HomePage = lazy(() => import('../Dashboard'));
const LoadingBlock = lazy(() => import('../Loading'));

const App = () => (
  <div className='app'>
    <Router>
      <CssBaseline />
      <div className='app-page-content'>
        <Suspense fallback={<LoadingBlock />}>
          <ErrorBoundary>
            <Routes>
              <Route exact path={ROUTES.LANDING} element={<HomePage />} />
            </Routes>
          </ErrorBoundary>
        </Suspense>
      </div>
      <Footer />
      <Alerts />
    </Router>
  </div>
);

export default App;
