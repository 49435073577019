import React from 'react';
import { makeStyles } from '@mui/styles';

//import MetaStudiosGreenM from '../../images/meta_studios_green_m_animate';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: 16,
    },
    height: '100vh',
  },
}));

export default function LoadingPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper>
        <Grid container direction='row' justifyContent='center' alignItems='center' item xs={12}>
          <Grid item xs={1} align='center'>
            <CircularProgress />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
