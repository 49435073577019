export const LANDING = '/';
export const LOGIN = '/login';
export const ADMIN = '/admin';
export const SIGN_UP = '/signup';
export const SIGN_OUT = '/signout';
export const PASSWORD_FORGET = '/password-reset';
export const USERS = '/users/:id';
export const USER = '/users/';
export const COMPANY = '/company/:id';
export const EDIT_COMPANY = '/company/edit/:id';
